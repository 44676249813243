import axios from "axios";

import {useState, useEffect} from 'react'
import { useNavigate, Navigate, useLocation } from 'react-router-dom';

import Navbar from './Navbar'
import Topbar from './Topbar'
import { paste } from "@testing-library/user-event/dist/paste";

function ConvidarCampanha() {

    const locate = useLocation()

    const navigate = useNavigate()

    const myid = locate.state.campanha_id
    const mytitulo = locate.state.titulo

    const [results, setResults] = useState([])
    const [convidados, setConvidados] = useState()

    useEffect(() => {
        if (!sessionStorage.getItem("tokeninovacao")) {
            navigate('/login')
        }
        listresume( sessionStorage.getItem("tokeninovacao"))
    }, [])

    const listresume= async (mytoken) => {
        
        try {
            const res = await axios.post(`/Account/ListUsers`, {TOKEN : mytoken});
            if (res.data) {
                setResults(res.data);
            } else {
                alert("erro na listagem.")
            }
        } catch (err) {
            alert("Ocorreu um erro. Tente novamente.");
        }
    }  

    const  doSend = async (e) => {
        e.preventDefault()
        try {
           // alert(convidados)
            if (convidados) {
                for (let i = 0; i < convidados.length; i++) {
                    const res = await axios.post(`/Inovation/InsertParticipant`, {TOKEN:  sessionStorage.getItem("tokeninovacao") , Id_Process : myid, Id_Participant: convidados[i]})
                    if (!res.data.Return) {
                        alert(res.data.Description)
                        return
                    }
                } 
                navigate("/campanhaunica", {state:{campanha_id: myid}})
            }

        }
        catch(err){
            alert(err)
        }

    }

    let novalistaconvidados = []
    function doConvidados(num) {
        if (convidados) {
            novalistaconvidados = convidados
        }
        novalistaconvidados.push(num)
        //alert(novalistaconvidados)
        setConvidados(novalistaconvidados)
        
    }


  return (
    <div id="wrapper" className="fundocinzaescuro">
        <Navbar />
        <div id="page-wrapper" className="gray-bg">
            <Topbar />
            <div className="row border-bottom white-bg" style={{minHeight: '100%', paddingLeft: 30}}>
                <div className="wrapper wrapper-content animated fadeInRight white-bg" >
                    <h1>{mytitulo}</h1>

                    <form onSubmit={(e)=> doSend(e)} role="form" style={{marginTop:20}}>
                        <div className="form-group"><label>Convidar para Campanha</label> 
                        <div>
                            <table class="table table-bordered">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Nome</th>
                                    <th>Departamento</th>
                                  </tr>
                                </thead>
                                <tbody>
                    
                                {results &&  results.map((item, i) => {
                                    return(

                                            <tr key={item.ID}>
                                                <td><input type="checkbox" onChange={() =>doConvidados(item.ID)}/></td>
                                                <td>{item.NOME}</td>
                                                <td>{item.DEPARTAMENTO}</td>
                                            </tr>
                                
                                    )
                                })}

                                </tbody>
                            </table>
                            
                            <button className="btn btn-sm btn-primary pull-right m-t-n-xs" type="submit"><strong>Convidar</strong></button>
                            
                        </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
  );
}

export default ConvidarCampanha;