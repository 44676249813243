import { Link } from "react-router-dom";
import logo from './logo-inovacao.png'

function Navbar() {



    return(

        <nav className="navbar-default navbar-static-side" role="navigation" style={{backgroundColor: "#2f4050" }}>
            <div className="sidebar-collapse" style={{backgroundColor: "#2f4050" }}>
                <ul className="nav metismenu" id="side-menu" style={{backgroundColor: "#2f4050" }}>
                    <li className="nav-header" style={{backgroundColor: "#2f4050", padding:10, marginBottom:20 }}>
                        <div className="row">
                            <div   className="col-xs-3">
                                <img src={logo} alt="logo" style={{height:40, width:40,}} />
                            </div>
                            <div className="col-xs-9 " style={{color:'white',height: 40, display: 'flex',  alignItems: 'center'}} >
                                <h3 style={{margin:0, }}>SUITI</h3></div>
                        </div>
                    </li>
                    <li>
                        <Link to="/home"><i className="fa fa-home"></i> <span className="nav-label">Home</span></Link>
                    </li>
                    <li>
                        <Link to="/ideias"><i className="fa fa-lightbulb-o"></i> <span className="nav-label">Ideias</span></Link>
                    </li>
                    <li>
                        <Link to="/campanhas"><i className="fa fa-list"></i> <span className="nav-label">Campanhas</span></Link>
                    </li>

                    {((sessionStorage.getItem("tipousuario") == 1) || (sessionStorage.getItem("tipousuario") == 3))  &&

                        <li>
                        <Link to="/tarefas"><i className="fa fa-table"></i> <span className="nav-label">Tarefas</span></Link>
                        </li>
                    }

                    {(sessionStorage.getItem("tipousuario") == 3)  &&
                        <li>
                            <Link to="/usuarios"><i className="fa fa-user"></i> <span className="nav-label">Usuários</span></Link>
                        </li>
                    }

                    {(sessionStorage.getItem("tipousuario") == 3)  &&
                        <li>
                            <Link to=""><i className="fa fa-gear"></i> <span className="nav-label">Configurações</span></Link>
                        </li>
                    }
                    
                </ul>

            </div>
        </nav>
    )
}

export default Navbar