import axios from "axios";

import {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';

import Navbar from './Navbar'
import Topbar from './Topbar'

function NovaCampanha() {

    const navigate = useNavigate()

    const [titulo, setTitulo] = useState()
    const [descricao, setDescricao] = useState()

    useEffect(() => {
        if (!sessionStorage.getItem("tokeninovacao")) {
            navigate('/login')
        }
       
    }, [])

    const  doSend = async (e) => {
        e.preventDefault()
        try {
            
            const res = await axios.post(`/Inovation/InsertCampaign`, {TOKEN:  sessionStorage.getItem("tokeninovacao") , Resume : titulo, Description: descricao})
            if (res.data.Return) {
                navigate('/Campanhas')
            } else {
                alert(res.data.Description)
            }
        }
        catch(err){
            alert(err)
        }

    }


  return (
    <div id="wrapper"  className="fundocinzaescuro">
        <Navbar />
        <div id="page-wrapper" className="gray-bg">
            <Topbar />
            <div className="row border-bottom white-bg" style={{minHeight: '100%', paddingLeft: 30}}>
                <div className="wrapper wrapper-content animated fadeInRight white-bg" >
                    <h1>Nova Campanha de Ideias</h1>

                    <form onSubmit={(e)=> doSend(e)} role="form">
                        <div className="form-group"><label>Título</label> <input type="text"  className="form-control" onChange={(e)=> setTitulo(e.target.value)}/></div>
                        <div className="form-group"><label>Descrição</label> <textarea  rows={6}  className="form-control" onChange={(e)=> setDescricao(e.target.value)}/></div>
                        <div>
                            <button className="btn btn-sm btn-primary pull-right m-t-n-xs" type="submit"><strong>Enviar</strong></button>
                            
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
  );
}

export default NovaCampanha;