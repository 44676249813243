import axios from "axios";

import Navbar from './Navbar'
import Topbar from './Topbar'

import {useState, useEffect} from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';

function IdeiaUnica() {

    const locate = useLocation()

    const navigate = useNavigate()

    const [results, setResults] = useState([])
    const [messages, setMessages] = useState([])
    const [messagescodes, setMessagescodes] = useState()

    const [resultsanalise, setResultsAnalise] = useState([])
    const [messagesanalise, setMessagesAnalise] = useState()
    const [formsanalise, setFormsAnalise] = useState([])
    const [anexosanalise, setAnexosAnalise] = useState([])

    const myid = locate.state.ideia_id

    let tipomessage


    useEffect(() => {
        if (!sessionStorage.getItem("tokeninovacao")) {
            navigate('/login')
        }
        listresume( sessionStorage.getItem("tokeninovacao"))
        veranalise( sessionStorage.getItem("tokeninovacao"))
    }, [])

    const listresume= async (mytoken) => {
        
        try {
            const res = await axios.post(`/Inovation/Inovation`, {TOKEN : mytoken, Id :myid});
            if (res.data) {
                setResults(res.data);
                setMessages(res.data.Messages.slice(0,-1))
                if (messages) {
                    setMessagescodes(Object.keys(messages))
                }
            }
        } catch (err) {
            alert("Ocorreu um erro. Tente novamente.");
        }
    }  

    const veranalise= async (mytoken) => {
        
        try {
            const res = await axios.post(`/Process/Tasks`, {TOKEN : mytoken, Id_Process: myid, Status: "Terminado", Type: "Análise"});
            if (res.data.length > 0) {
                const res2 = await axios.post(`/Process/IdentifyTask`, {TOKEN : mytoken, Id: res.data[0].ID});
                setResultsAnalise(res2.data.Messages);
                //setMessagesAnalise(res2.data.Messages.filter((Id_Associated_Item) => Id_Associated_Item = res2.data[0].ID))
                //alert(res2.data.Messages)
                setFormsAnalise(res2.data.Forms)
                setAnexosAnalise(res2.data.Attachments)
            }
        } catch (err) {
            alert("Ocorreu um erro na analise. Tente novamente.");
        }
    }  

    function retornadata(data) {
        let myday
        let mymonth
        let myyear
        if (data && isNaN(new Date(data).getDate())) {
            //não converteu direito
            myday = data.substring(0, 2)
            mymonth = data.substring(3, 5)
            myyear = data.substring(6,10)
        } else {
            myday = new Date(data).getDate()
            mymonth = new Date(data).getMonth()+1
            myyear = new Date(data).getFullYear()
        }
        const mydate = myday + '/' + mymonth + '/' + myyear
        return mydate
    }

    function verificadata(data) {
        // if (isNaN(new Date(data).getFullYear())) {
        //     return 0
        // } else {
        //     return 1
        // }
        return new Date(data).getFullYear()
            
    }

    return (

        <div id="wrapper" className="fundocinzaescuro">

           <Navbar />

            <div id="page-wrapper" className="gray-bg">

                <Topbar />

                    {results &&  [results].map((item, i) => {
                            return(
                                   
                                    <div key={item.ID} >
                                        <div className="row border-bottom white-bg" style={{minHeight: '100%', paddingLeft: 30}}>
                                        <div className="wrapper-content animated fadeInRight white-bg" >
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <h5  style={{marginBottom:0}}>Número: {item.Number}</h5>
                                                    <h1 style={{marginTop:5, marginBottom: 10, fontWeight: "bold"}}>{item.Name}</h1>
                                                    <h5 style={{marginTop:0}}>Criado em: {retornadata(item.Creation_Date)} - Data Limite: {retornadata(item.Limit_Date)}</h5>
                                                    <p style={{marginTop:40}}>{item.Description}</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="widget style1 navy-bg text-center">
                                                        <h4>STATUS</h4>
                                                       

                                                        {(item.Approved == true && verificadata(item.Approval_Date) != 1 )
                                                        ?
                                                            <h2>Aprovado</h2>
                                                        :
                                                            (item.Approved== false && verificadata(item.Approval_Date) != 1)
                                                            ?
                                                                <h2>Reprovado</h2>
                                                            :
                                                            <h2>{item.Status}</h2>
                                                             
                                                        }
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                       
                                    </div>
                                )
                            })
                    }
                    
                    <div className="row" style={{marginTop:40}}>
                            <div className="col-xs-6 text-left">
                                <h3 >Comentários</h3>
                            </div>
                            <div className="col-xs-6 text-right">
                            <Link to="/novocomentarioideia" state={{ideia_id: myid, titulo: results.Name}}><button className="btn btn-outline btn-sm btn-primary pull-right m-t-n-xs" type="submit"><strong>Novo Comentário</strong></button></Link>
                            </div>
                    </div>
                    <div className="ibox-content ibox-heading  white-bg" style={{marginTop:10}}>
                    {messages && messages.map((subitem, i) => {
                        tipomessage = subitem.IsProcessMessage
                        if (tipomessage == true && subitem.Description.includes("Finalizada") == false) {
                            return (
                                <div  key={i}>
                                    
                                    <div className="row " style={{ marginBottom: 20}}>
                                        <div className="col-xs-1 center-block">
                                            <i className="fa fa-user-circle-o fa-3x center-block" style={{color:'#1ab394', margin: "auto"}}></i>
                                        </div>
                                        <div className="col-xs-11 text-left">
                                            <strong>{subitem.User}</strong>
                                            <p>{subitem.Description}</p>
                                            <small >{retornadata(subitem.Creation_Date)}</small>
                                            
                                        </div>
                                    </div>
                                    <div className="row border-bottom" style={{ marginBottom: 20}}>
                                    </div>
                                </div>
                                
                            )
                            }
                    })}
                    </div>

                    {sessionStorage.getItem("tipousuario") != 4 && resultsanalise.length>0 &&
                    <div>
                        <div className="row" style={{marginTop:40}}>
                                <div className="col-xs-6 text-left">
                                    <h3 >Análise</h3>
                                </div>
                                <div className="col-xs-6 text-right">
                                <Link to="/novocomentarioideia" state={{ideia_id: myid, titulo: results.Name}}><button className="btn btn-outline btn-sm btn-primary pull-right m-t-n-xs" type="submit"><strong>Novo Comentário</strong></button></Link>
                                </div>
                        </div>
                        <div className="ibox-content ibox-heading  white-bg" style={{marginTop:10}}>
                        {resultsanalise && [resultsanalise].map((subitem, i) => {
                        return (
                            <div  key={i}>
                                
                                <div className="row " style={{ marginBottom: 20}}>
                                    <div className="col-xs-1 center-block">
                                        <i className="fa fa-user-circle-o fa-3x center-block" style={{color:'#1ab394', margin: "auto"}}></i>
                                    </div>
                                    <div className="col-xs-11 text-left">
                                        <strong>{subitem.Executer}</strong>
                                        <p>{subitem.Executer}</p>
                                        <small >{retornadata(subitem.Finish_Date)}</small>
                                        
                                    </div>
                                </div>
                                <div className="row border-bottom" style={{ marginBottom: 20}}>
                                </div>
                            </div>
                            
                        )
                        })}
                        </div>
                    </div>
                    }


            </div>
        </div>
      );
    }
    
    export default IdeiaUnica;
