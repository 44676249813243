import axios from "axios";

import {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';

import Navbar from './Navbar'
import Topbar from './Topbar'

function NovaIdeia() {

    const navigate = useNavigate()

    const [titulo, setTitulo] = useState()
    const [descricao, setDescricao] = useState()

    useEffect(() => {
        if (!sessionStorage.getItem("tokeninovacao")) {
            navigate('/login')
        }
       
    }, [])

    const  doSend = async (e) => {
        e.preventDefault()
        try {

            const res = await axios.post(`/Inovation/InsertInovation`, {TOKEN:  sessionStorage.getItem("tokeninovacao") , Resume : titulo, Description: descricao})
            if (res.data.Return) {
                navigate('/ideias')
            } else {
                alert(res.data.Description)
            }
        }
        catch(err){
            alert(err)
        }

    }


  return (
    <div id="wrapper"  className="fundocinzaescuro">
        <Navbar />
        <div id="page-wrapper" className="gray-bg" style={{backgroundImage: "url(img/landing/word_map.png)"}}>
            <Topbar />
            <div className="row border-bottom white-bg" style={{height: '100%', marginRight: 50, marginLeft: 50, marginTop: 50, paddingRight: 30, paddingLeft: 30, paddingBottom: 30, }}>
                <div className="wrapper wrapper-content animated fadeInRight white-bg" >
                    <h1>Nova ideia</h1>
                    <div className="space40"></div>
                    <form onSubmit={(e)=> doSend(e)} role="form">
                        <div className="form-group"><label>Título</label> <input type="text"  className="form-control" onChange={(e)=> setTitulo(e.target.value)}/></div>
                        <div className="form-group"><label>Descrição</label> <textarea  rows={6}  className="form-control" onChange={(e)=> setDescricao(e.target.value)}/></div>
                        <div style={{ display: 'flex',  alignItems: 'baseline', justifyContent:'space-between', marginTop: 40}}>
                            <button style={{marginRight: 20}} className="btn btn-lg btn-warning btn-outline pull-right m-t-n-xs" ><strong>Anexar</strong></button>
                            <div style={{ display: 'flex',  alignItems: 'baseline', justifyContent:'end'}}>
                                <button style={{marginRight: 20}} className="btn btn-lg btn-primary btn-outline pull-right m-t-n-xs" ><strong>Salvar Rascunho</strong></button>
                                <button className="btn btn-lg btn-primary pull-right m-t-n-xs" type="submit"><strong>Enviar</strong></button>
                            </div>
                            
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
  );
}

export default NovaIdeia;