import axios from "axios";

import Navbar from './Navbar'
import Topbar from './Topbar'

import {useState, useEffect} from 'react'
import { Link, useNavigate } from 'react-router-dom';

function Ideias() {

    const [results, setResults] = useState([])
    const [origem, setOrigem] = useState()
    const [classificacao, setClassificacao] = useState()
    const [tipo, setTipo] = useState()
    const [listaclassificacao, setListaclassificacao] = useState('')
    const [listatipo, setListatipo] = useState('')
    const [status, setStatus] = useState()
    const [texto, setTexto] = useState()
    const [mydisplay, setMydisplay] = useState('none') 

    const navigate = useNavigate()

    useEffect(() => {
        if (!sessionStorage.getItem("tokeninovacao")) {
            navigate('/login')
        }
        listresume( sessionStorage.getItem("tokeninovacao"))
    }, [])

    const listresume= async (mytoken) => {
        
        try {
            const res = await axios.post(`/Inovation/Inovations`, {TOKEN : mytoken});
            if (res.data) {
                setResults(res.data);
                //pega as classificações existentes
                const res2 = await axios.post(`/Process/ListProcessClassificationForService`, {TOKEN : mytoken, Id_Service: "5716", Id_Process_Category:"10351"});
                if (res2.data[0].id) {
                    setListaclassificacao(res2.data);
                } else {
                    alert("Erro na seleção de classificação")
                }
            }
        } catch (err) {
            alert("Ocorreu um erro. Tente novamente.");
        }
    }  

    const doFilter= async (e) => {
        e.preventDefault()
        try {
            if (status != "Aprovado" && status != "Reprovado") {
                const res = await axios.post(`/Inovation/Inovations`, {TOKEN : sessionStorage.getItem("tokeninovacao"), Status: status, Search: texto, Id_Classification : classificacao, Id_Requester: origem });
                if (res.data) {
                    setResults(res.data);
                    //setMydisplay('none')
                } else {
                    alert("nada encocntrado")
                }
            } else {
                let myaprov
                if (status == "Aprovado") {
                    myaprov = true
                } else {
                    //reprovado
                    myaprov = false
                }
                const res2= await axios.post(`/Inovation/Inovations`, {TOKEN : sessionStorage.getItem("tokeninovacao"), OnlyApproved:myaprov, ListAprovedInnovations: true });
                if (res2.data) {
                    setResults(res2.data);
                    //setMydisplay('none')
                } else {
                    alert("nada encocntrado")
                }
            }
        } catch (err) {
            alert("Ocorreu um erro. Tente novamente.");
        }
    }  

    const listartipos= async (classificacao) => {
        setClassificacao(classificacao)
        if ( classificacao != "a Classificar" ) {
            
            try {
                
                //pega os tipos existentes
                const res3 = await axios.post(`/Process/ListProcessClassificationTypesForService`, {TOKEN : sessionStorage.getItem("tokeninovacao"), Id_Service: "5716", Id_Process_Category: "10351", Id_Classification: classificacao});
                if (res3.data[0].id) {
                    setListatipo(res3.data);
                } else {
                    alert("Erro na listagem de tipos!")
                }
            } catch (err) {
                alert("Ocorreu um erro na chamada.");
            }
        } else {
            setListatipo()
        }
        
    }

    function mostrafiltro() {
        if (mydisplay == 'block') {
            setMydisplay('none')
        }else {
            setMydisplay('block')
        }
    }

    function retornadata(data) {
        const myday = new Date(data).getDate()
        const mymonth = new Date(data).getMonth()+1
        const myyear = new Date(data).getFullYear()
        const mydate = myday + '/' + mymonth + '/' + myyear
        return mydate
    }

    return (

        <div id="wrapper" className="fundocinzaescuro">

           <Navbar />

            <div id="page-wrapper" className="gray-bg">

                <Topbar />

                
                <div className="row border-bottom white-bg" style={{minHeight: '100%', paddingLeft: 30}}>
                    <div className="wrapper wrapper-content animated fadeInRight white-bg" >
                        <div className="row" style={{display:'flex', alignItems: 'center'}}>
                            <div className="col-xs-6 text-left" style={{display:'flex', alignItems: 'center'}}>
                                <h1>Ideias</h1>
                                <button onClick={()=> mostrafiltro()} className="btn btn-primary " style={{marginTop: 10, marginLeft:10, alignItems: 'center', padding:5}}><i className="fa fa-filter fa-1x" ></i></button>
                                
                            </div>
                            <div className="col-xs-6 text-left" style={{display:'flex', alignItems: 'center', justifyContent: 'end'}}>
                                <Link to="/novaideia"><button className="btn btn-xs  btn-primary" style={{marginTop: 10, marginLeft:40, alignItems: 'center', padding:5}}><i className=" fa fa-plus-circle"></i> Nova Ideia </button></Link>
                            </div>
                        </div>

                        <div className="wrapper wrapper-content  white-bg" style={{marginTop:20,paddingLeft: 20, paddingBottom:40, display: mydisplay}}>
                                <h3 style={{paddingBottom:20}} >Selecione o filtro:</h3>
                                <form onSubmit={(e)=> doFilter(e)} role="form" >
                                <div style={{display: 'flex', justifyContent: 'flex-start',paddingBottom:20}}>
                                    {sessionStorage.getItem("tipousuario") != 4 &&
                                        <div className="form-group" style={{marginRight:20}}><label>Origem: </label> 
                                            <select  onChange={(e)=> setOrigem(e.target.value)} className="form-control">
                                                <option value="">Todas</option>
                                                <option value={sessionStorage.getItem("codigousuario")}>Só as minhas</option>
                                            </select>
                                        </div>
                                    }
                                    <div className="form-group" style={{marginRight:20}}><label>Status: </label> 
                                        <select  onChange={(e)=> setStatus(e.target.value)} className="form-control">
                                            <option value="">Todas</option>
                                            <option value="em Aberto">em Aberto</option>
                                            <option value="em Andamento">em Andamento</option>
                                            <option value="Cancelado">Canceladas</option>
                                            <option value="Aprovado">Aprovadas</option>
                                            <option value="Reprovado">Reprovadas</option>
                                        </select>
                                    </div>
                                    <div className="form-group" style={{marginRight:20}}>
                                        <label >Classificação: </label> 
                                        <select  onChange={(e)=> listartipos(e.target.value)} className="form-control">
                                            <option value="a Classificar">Todas</option>
                                            {listaclassificacao &&  listaclassificacao.map((item, i) => {
                                                return(
                                                    <option key={i} value={item.id}>{item.value}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div className="form-group" style={{marginRight:20}}>
                                        <label >Tipo: </label> 
                                        <select  onChange={(e)=> setTipo(e.target.value)} className="form-control">
                                            {classificacao &&
                                                <option value="">Selecione...</option>
                                            }
                                            {listatipo &&  listatipo.map((item, i) => {
                                                return(
                                                    <option key={i} value={item.id}>{item.value}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div className="form-group" style={{marginRight:20}}><label>Texto: </label> 
                                        <input type="text" className="form-control" required="" onChange={(e)=> setTexto(e.target.value)} />
                
                                    </div>
                                </div> 
                                    
                                <button className="form-group btn btn-sm btn-primary pull-left m-t-n-xs" type="submit"><strong>Filtrar</strong></button>
                                </form>

                        </div>
                        
                        {results &&  results.map((item, i) => {

                            
                                return(
                                    <div key={item.ID} >
                                        <div className="ibox-content ibox-heading" style={{marginTop:20}}>
                                                <h5>Número: {item.NUMERO} - ID: {item.ID} - ID: {item.SOLICITANTE}</h5>
                                                <h3 style={{fontWeight: "bold"}}>{item.TITULO}</h3>
                                   
                                                <h5>Criado em: {retornadata(item.DATA_ABERTURA)} - Data Limite: {retornadata(item.DATA_LIMITE)}</h5>

                                                <Link to="/ideiaunica" state={{ideia_id: item.ID}}><button className="btn btn-xs btn-outline btn-primary" >Info <i className=" fa fa-long-arrow-right"></i></button></Link>
                                        </div>
                                    </div>
                                )
                            })
                            }
                    </div>


                   


                </div>
                
            </div>


            
        
            

        </div>
      );
    }
    
    export default Ideias;
