import logo from './logo-inovacao.png'
import axios from "axios";

import {useState, useEffect} from 'react'
import { Link, useNavigate } from 'react-router-dom';

import Navbar from './Navbar'
import Topbar from './Topbar'

function CadastroUsuario() {

    const navigate = useNavigate()

    const [nome, setNome] = useState()
    const [tipo, setTipo] = useState()
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()

    useEffect(() => {
        if (!sessionStorage.getItem("tokeninovacao")) {
            navigate('/login')
        }
       
    }, [])

    const  doCadastro = async (e) => {
        e.preventDefault()
        if (!nome || !tipo || !email || !password) {
            alert("Faltam dados para o registro!")
            return
        }
        try {
            const res = await axios.post(`Account/Insert`, {TOKEN: "@@@@@", ID_Company: sessionStorage.getItem("codigoempresa"), Name: nome, Login: email, Email: email, Password: password, Type: tipo})
            if (res.data.Return == false) {
                if (res.data.Code == 100) {
                    alert("E-mail já cadastrado!")
                    return
                    //code 103 empresa já existe
                } else {
                    alert("erro na inclusao do funcionário")
                }
            } else {
                 navigate('/usuarios')
            }
        }
        catch(err){
            alert(err)
        }

    }


  return (
    <div id="wrapper"  className="fundocinzaescuro">
            <Navbar />
            <div id="page-wrapper" className="gray-bg">
                <Topbar />
                <div className="row border-bottom white-bg" style={{minHeight: '100%', paddingLeft: 30, paddingBottom: 30}}>
                    <div className="wrapper wrapper-content animated fadeInRight white-bg" >
                        <h1>Cadastro de Usuário</h1>

                        <form onSubmit={(e)=> doCadastro(e)} class="m-t" role="form" >
                            
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Nome" required=""  onChange={(e)=> setNome(e.target.value)}/>
                            </div>
                            <div class="form-group">
                                <input type="email" class="form-control" placeholder="Email" required=""  onChange={(e)=> setEmail(e.target.value)}/>
                            </div>
                            <div class="form-group">
                                <input type="password" class="form-control" placeholder="Senha" required=""  onChange={(e)=> setPassword(e.target.value)}/>
                            </div>
                            <div className="form-group" > 
                                        <select  onChange={(e)=> setTipo(e.target.value)} className="form-control">
                                            <option value="">Tipo...</option>
                                            <option value="3">Administrador</option>
                                            <option value="1">Analista</option>
                                            <option value="4">Usuário</option>
                                        </select>
                                    </div>
                            <div>
                                <button type="submit" class="btn btn-lg btn-primary pull-right m-t-n-xs">Cadastrar</button>
                            </div>

                            
                        </form>
                        
                    </div>
                </div>
            </div>
        </div>
  );
}

export default CadastroUsuario;