import axios from "axios";

import {useState, useEffect} from 'react'
import { useNavigate, Navigate, useLocation } from 'react-router-dom';

import Navbar from './Navbar'
import Topbar from './Topbar'

function NovoComentarioIdeia() {

    const locate = useLocation()

    const navigate = useNavigate()

    const myid = locate.state.ideia_id
    const mytitulo = locate.state.titulo


    const [descricao, setDescricao] = useState()

    useEffect(() => {
        if (!sessionStorage.getItem("tokeninovacao")) {
            navigate('/login')
        }
    }, [])

    const  doSend = async (e) => {
        e.preventDefault()
        try {

            const res = await axios.post(`/Inovation/AddMessageToInovation`, {TOKEN:  sessionStorage.getItem("tokeninovacao") , Id : myid, Message: descricao})
            if (res.data.Return) {
                navigate("/ideiaunica", {state:{ideia_id: myid}})
            } else {
                alert(res.data.Description)
            }
        }
        catch(err){
            alert(err)
        }

    }


  return (
    <div id="wrapper" className="fundocinzaescuro">
        <Navbar />
        <div id="page-wrapper" className="gray-bg">
            <Topbar />
            <div className="row border-bottom white-bg" style={{minHeight: '100%', paddingLeft: 30}}>
                <div className="wrapper wrapper-content animated fadeInRight white-bg" >
                    <h1>{mytitulo}</h1>

                    <form onSubmit={(e)=> doSend(e)} role="form" style={{marginTop:20}}>
                        <div className="form-group"><label>Comentário</label> <textarea  rows={6}  className="form-control" onChange={(e)=> setDescricao(e.target.value)}/></div>
                        <div>
                            <button className="btn btn-sm btn-primary pull-right m-t-n-xs" type="submit"><strong>Enviar</strong></button>
                            
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
  );
}

export default NovoComentarioIdeia;