import logo from './logo-inovacao.png'
import axios from "axios";

import Navbar from './Navbar'
import Topbar from './Topbar'

import {useState, useEffect} from 'react'
import { Link, useNavigate } from 'react-router-dom';

function Home() {

    const [results, setResults] = useState([])

    const navigate = useNavigate()

    useEffect(() => {
        if (!sessionStorage.getItem("tokeninovacao")) {
            navigate('/login')
        }
        listresume( sessionStorage.getItem("tokeninovacao"))
    }, [])

    const listresume= async (mytoken) => {
        
        try {
            const res = await axios.post(`Inovation/Stats/`, {TOKEN : mytoken});
            if (res.data) {
                setResults(res.data);
            }
        } catch (err) {
            alert("Ocorreu um erro. Tente novamente.");
        }
    }  

    return (

        <div id="wrapper"  className="fundocinzaescuro">
           <Navbar />
            <div id="page-wrapper" className="gray-bg">
                <Topbar />
                <div className="row border-bottom white-bg" style={{minHeight: '100%', paddingLeft: 30}}>
                    <div className="wrapper wrapper-content animated fadeInRight white-bg" >
                    <h1>Home</h1>
                        <div className="row" >
                            <div className="col-lg-4">
                                <div className="widget style1 navy-bg">
                                        <div className="row">
                                            <div className="col-xs-4 text-center">
                                                <i className="fa fa-lightbulb-o fa-5x"></i>
                                            </div>
                                            <div className="col-xs-8 text-right">
                                                <span> IDEIAS OFERECIDAS </span>
                                                <h2 className="font-bold">{results.Qty_Innovations}</h2>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="widget style1 navy-bg">
                                    <div className="row">
                                        <div className="col-xs-4">
                                            <i className="fa fa-check-circle fa-5x"></i>
                                        </div>
                                        <div className="col-xs-8 text-right">
                                            <span> IDEIAS APROVADAS </span>
                                            <h2 className="font-bold">{results.Qty_Innovations_Approved}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="widget style1 navy-bg">
                                    <div className="row">
                                        <div className="col-xs-4">
                                            <i className="fa fa-group fa-5x"></i>
                                        </div>
                                        <div className="col-xs-8 text-right">
                                            <span> CAMPANHAS ENGAJADAS </span>
                                            <h2 className="font-bold">{results.Qty_Campaings_Active}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{marginRight: 30,position: 'absolute', right:40,  bottom: 40, }}>
                        <Link to="/novaideia"> <i className="fa fa-plus-circle fa-5x" style={{color:'orange'}}></i></Link>
                    </div>






                </div>
            </div>


            
        
            

        </div>
      );
    }
    
    export default Home;
