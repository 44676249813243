import axios from "axios";

import Navbar from './Navbar'
import Topbar from './Topbar'


import {useState, useEffect} from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';

function TarefaUnica() {

    const locate = useLocation()

    const navigate = useNavigate()

    const [myid, setMyid] = useState()
    const [results, setResults] = useState([])
    const [messages, setMessages] = useState()
    const [messagescodes, setMessagescodes] = useState()

    const [displaydescarte, setDisplaydescarte] = useState('none')
    const [razao, setRazao] = useState('')
    const [tarefa, setTarefa] = useState('') 
    const [displayanalise, setDisplayanalise] = useState(false)
    const [razaoresultado, setRazaoresultado] = useState('')

    const [listaclassificacao, setListaclassificacao] = useState('')
    const [listatipo, setListatipo] = useState('')
    const [classificacao, setClassificacao] = useState('')
    const [tipo, setTipo] = useState('')



    const mytoken = sessionStorage.getItem("tokeninovacao")
    const myprocessid = locate.state.processo_id
    const myprocessnumber = locate.state.processo_numero


    let tipomessage
    

    useEffect(() => {
        if (!sessionStorage.getItem("tokeninovacao")) {
            navigate('/login')
        }
        listresume()
    }, [tarefa])

    const listresume= async () => {
        try {
            
            const res1 = await axios.post(`/Process/Tasks`, {TOKEN : mytoken, Id_Process: myprocessid});
            if (res1.data.length > 0) {
                setMyid(res1.data[0].ID)
                const res = await axios.post(`/Process/IdentifyTask`, {TOKEN : mytoken, Id: res1.data[0].ID});
                if (res.data.Type) {
                    setResults(res.data);
                    setMessages(res.data.Messages)
                    if (messages) {
                        setMessagescodes(Object.keys(messages))
                    }
                }
                //pega as classificações existentes
                const res2 = await axios.post(`/Process/ListProcessClassificationForService`, {TOKEN : mytoken, Id_Service: "5716", Id_Process_Category:"10351"});
                if (res2.data[0].id) {
                    setListaclassificacao(res2.data);
                } else {
                    alert("Erro na seleção de classificação")
                }
                

            } else {
                //não há mais tarefas para a ideia
                navigate("/tarefas")
            }
           
        } catch (err) {
            alert("Ocorreu um erro. Tente novamente.");
        }
    }  

    

    const iniciartarefa= async (tipotarefa) => {
        
        try {
            const res = await axios.post(`/Process/StartTask`, {TOKEN : mytoken, Id_Task: myid});
            if (res.data.Return) {
                //por enquanto só uso o state abaixo para a pagina ser relida
                listresume()
                //navigate('/tarefaunica', {state: {tarefa_id: myid}})
            } else {
                alert('Erro na abertura da tarefa!')
            }
        } catch (err) {
            alert("Ocorreu um erro na chamada.");
        }
    } 
    
    const formdescartar= async (e) => {
        e.preventDefault()
        if ( displaydescarte == 'none'){
            setDisplaydescarte('flex')
        } else {
            setDisplaydescarte('none')
        }
    } 

    const descartar= async (e) => {
        e.preventDefault()
        alert(myprocessid)
        if ( !razao) {
            alert('Precisa escolher uma razão!')
        } else {
            alert(myprocessid)
            try {
                const res = await axios.post(`/Process/CancelProcess`, {TOKEN : mytoken, Id: myprocessid, Number: myprocessnumber, Motive: razao});
                if (res.data.Return) {
                    navigate('/tarefas')
                } else {
                    alert('Erro no cancelamento da tarefa!')
                }
            } catch (err) {
                alert("Ocorreu um erro na chamada.");
            }
        }
        
    } 

    const doClassify= async (e) => {
        e.preventDefault()
        if ( !classificacao || !tipo) {
            alert('Precisa escolher a classificação e o tipo!')
        } else {
            try {
                alert(myprocessid)
                alert(classificacao)
                const res = await axios.post(`/Process/ClassifyProcess`, {TOKEN : mytoken, Id: myprocessid, Id_Type : 9026, Id_Classification: classificacao, Id_Type: tipo});
                if (res.data.Return) {
                    listresume()
                } else {
                    alert('Erro na classificação!')
                }
            } catch (err) {
                alert("Ocorreu um erro na chamada.");
            }
        }
        
    } 

    const listartipos= async (classificacao) => {
        alert(classificacao)
        if ( !classificacao ) {
            alert('Precisa escolher a classificação!')
        } else {
            try {
                setClassificacao(classificacao)
                //pega os tipos existentes
                const res3 = await axios.post(`/Process/ListProcessClassificationTypesForService`, {TOKEN : mytoken, Id_Service: "5716", Id_Process_Category: "10351", Id_Classification: classificacao});
                if (res3.data[0].id) {
                    setListatipo(res3.data);
                } else {
                    alert("Erro na listagem de tipos!")
                }
            } catch (err) {
                alert("Ocorreu um erro na chamada.");
            }
        }
        
    } 

    const mostraranalise= async () => {
        if (displayanalise == false) {
          setDisplayanalise(true)
        } else {
          setDisplayanalise(false)
        }
    } 

    const passartarefa = async (tipotarefa) => {
        
        try {
            //vai fechar essa tarefa de Validação, com status Terminado. Vai criar uma tarefa de Análise, com status em Aberto
            //na ideia não vai mudar nada
            const res = await axios.post(`/Process/FinishTask`, {TOKEN : mytoken, Id_Task: myid, AnwserFollowingQuestion:true, AnwserValue:true});
            if (res.data.Return) {
                listresume()
                //navigate('/tarefaunica', {state: {tarefa_id: myid}})
                //navigate('/tarefaunicaanalise', {state: {tarefa_id: myid}})
            } else {
                alert('Erro na passagem para análise!')
            }
        } catch (err) {
            alert("Ocorreu um erro na chamada.");
        }
    } 

    const resultado = async (num) => {
        if (razaoresultado == '') {
            alert("Precisa inserir um motivo!")
            return
        }
        try {
            const res = await axios.post(`/Process/ApproveRejectTask`, {TOKEN: mytoken, Id_Task: myid, IsApproved: num, Motive: razaoresultado});
            if (res.data.Return) {
                if (num == 1) {
                    listresume()
                } else {
                    navigate('/tarefas')
                }
                //setTarefa(num)
                //navigate('/tarefaunica', {state: {tarefa_id: myid}})
            } else {
                alert('Erro no resultado!')
            }
        } catch (err) {
            alert("Ocorreu um erro na chamada.");
        }
    } 

    function retornadata(data) {
        let myday
        let mymonth
        let myyear
        if (data && isNaN(new Date(data).getDate())) {
            //não converteu direito
            myday = data.substring(0, 2)
            mymonth = data.substring(3, 5)
            myyear = data.substring(6,10)
        } else {
            myday = new Date(data).getDate()
            mymonth = new Date(data).getMonth()+1
            myyear = new Date(data).getFullYear()
        }
        const mydate = myday + '/' + mymonth + '/' + myyear
        return mydate
    }

    function BarraEtapas(tipo) {
        return(
            <div style={{ display: 'flex',  alignItems: 'baseline', justifyContent:'start'}}>
                <h3 className="widget   " style={{ marginRight:20, borderColor: 'grey', borderWidth: 1, borderStyle: 'solid', hright: 50}}>Dados da Ideia </h3>
                <i style={{ marginRight:20}}  className=" fa fa-chevron-right"></i>

                {tipo == "Validação" ? (
                    <h3 className="widget btn-primary " style={{ marginRight:20, height: 50}}>Validação </h3>
                ) : (
                    <h3 className="widget   " style={{ marginRight:20, borderColor: 'grey', borderWidth: 1, borderStyle: 'solid', hright: 50}}>Validação </h3>
                )}
                <i style={{ marginRight:20}}  className=" fa fa-chevron-right"></i>
               
    
                {tipo == "Análise" ? (
                        <h3  className="widget btn-primary " style={{ marginRight:20, borderColor: 'grey', borderWidth: 1, borderStyle: 'solid', hright: 50}}>Análise </h3>
                ) : (
                        tipo == "Aprovação" ? (
                            <button  className="widget btn  " onClick={() => mostraranalise()} style={{ marginRight:20, borderColor: 'grey', borderWidth: 1, borderStyle: 'solid', hright: 50,  fontSize: 16}}>Análise </button>
                        ) : (
                            <h3  className="widget  " style={{ marginRight:20, borderColor: 'grey', borderWidth: 1, borderStyle: 'solid', hright: 50}}>Análise </h3>
                        )
                        
                )}
    
               
                <i style={{ marginRight:20}}  className=" fa fa-chevron-right"></i>
                
                {tipo == "Aprovação" ? (
                    <button  className="widget  btn-primary" onClick={() => mostraranalise()} style={{ marginRight:20, borderColor: 'grey', borderWidth: 1, borderStyle: 'solid', hright: 50,  fontSize: 16}}>Aprovação</button>
                ) : (
                    <h3  className="widget  " style={{ marginRight:20, borderColor: 'grey', borderWidth: 1, borderStyle: 'solid', hright: 50}}>Aprovação</h3>
                )
                }
            </div>
        )

    }

    function Comentarios() {
        if (messages) {
            messagescodes = Object.keys(messages)
            
        }
                                                  

        messagescodes.map((subitem, i) => {
             tipomessage = messages[subitem].Type
             alert(tipomessage)
             if (tipomessage == "Análise") {
                 return (
                     <div  key={i}>
                         <div className="row " style={{ marginBottom: 20}}>
                             <div className="col-xs-1 center-block">
                                 <i className="fa fa-user-circle-o fa-3x center-block" style={{color:'#1ab394', margin: "auto"}}></i>
                             </div>
                             <div className="col-xs-11 text-left">
                                 <strong>{messages[subitem].User}</strong>
                                 <p>{messages[subitem].Description}</p>
                                 <small >{retornadata(messages[subitem].Creation_Date)}</small>
                                 
                             </div>
                         </div>
                         <div className="row border-bottom" style={{ marginBottom: 20}}>
                         </div>
                     </div>
                 )
             }
         })
    }

    return (

        <div id="wrapper" className="fundocinzaescuro">

           <Navbar />

            <div id="page-wrapper" className="gray-bg">

                <Topbar />
                <div className="row border-bottom white-bg" style={{height: '100%', marginRight: 50, marginLeft: 50, marginTop: 50, paddingRight: 30, paddingLeft: 30, paddingBottom: 30, }}>
                    {results &&  [results].map((item, i) => {
                            return(
                                    
                                    <div key={item.ID} >
                                        <div className="row border-bottom white-bg" style={{minHeight: '100%', paddingLeft: 30}}>
                                        <div className="wrapper-content animated fadeInRight white-bg" >
                                            <div className="row" style={{ display: 'flex',  alignItems: 'baseline'}}>
                                                <h1 >{item.Process_Name}</h1>
                                                {/* <Link to="/ideiaunica" state={{ideia_id: item.Process_Id}}><button className="btn btn-xs btn-outline btn-primary" style={{marginLeft:30, marginBottom: 10 }}>Ver Ideia <i className=" fa fa-long-arrow-right"></i></button></Link> */}
                                                
                                            </div>
                                            {/* <p>{item.Process_Requester_Email} - {item.Process_Empresa}</p> */}
                                        </div>
                                        </div>
                                        
                                    </div>
                                )
                            })
                    }
                    
                    {results &&  results.Type == "Validação" &&
                        <div className="row wrapper-content animated fadeInRight " style={{marginTop: 20, paddingLeft: 30}} >
                            {BarraEtapas("Validação")}

                            

                            {results.Status == "em Aberto" &&
                                <div>
                                    <div class="tabbable-panel white-bg" style={{marginTop: 20, padding: 20, marginBottom: 30,height:100}}>
                                        <p>Clique no botão abaixo para começar a tarefa.</p>
                                    </div>
                                    <div style={{ display: 'flex',  alignItems: 'baseline', justifyContent:'end'}}>
                                    
                                        <button onClick={() => iniciartarefa(results.Type)} className="btn btn-lg  btn-primary" style={{padding: 10 }}>Iniciar Tarefa </button>
                                    </div>
                                </div>
                            }
                            {(results.Status == "em Andamento" ) &&
                                <div>
                                    <div className="wrapper-content animated fadeInRight white-bg" style={{marginTop: 20, marginBottom: 30, paddingLeft: 20}} >
                                    {displaydescarte == 'none' &&
                                        <div>
                                            <p>Clique nos botões abaixo para descartar a ideia ou passar para a próxima etapa.</p>
                                        </div>
                                    }
                                        <div style={{display: displaydescarte}}>
                                            <form onSubmit={(e)=> descartar(e)}  role="form" >
                                                <h3 style={{marginBottom: 20}}>Razão para o descarte da ideia:</h3>
                                                <div className="form-group">
                                                    <p><input onChange={(e)=>setRazao(e.target.value)} type='radio'  name="razaodescarte" value="Sem recursos"></input>  Sem recursos</p>
                                                </div>
                                                <div className="form-group">
                                                    <p><input  onChange={(e)=>setRazao(e.target.value)} type='radio'  name="razaodescarte" value="Já foi feito"></input>  Já foi feito</p>
                                                </div>
                                                <div className="form-group">
                                                    <p><input  onChange={(e)=>setRazao(e.target.value)} type='radio'  name="razaodescarte" value="Já há ideia similar"></input>  Já há ideia similar</p>
                                                </div>
                                                <div style={{ display: 'flex',  alignItems: 'baseline', justifyContent:'end', marginTop: 20}}>
                                                    <button onClick={(e)=> formdescartar(e)}  className="btn btn-primary btn-outline block full-width m-b" style={{marginRight: 20}}>Cancelar Descarte</button>
                                                    <button onClick={(e)=> descartar(e)} className="btn btn-primary block full-width m-b">Descartar</button>
                                                    
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    {displaydescarte == 'none' &&
                                        <div style={{ display: 'flex',  alignItems: 'baseline', justifyContent:'end'}}>
                                            <button onClick={(e)=> formdescartar(e)} className="btn btn-lg btn-outline btn-primary" style={{padding: 10, marginRight: 20 }}>Descartar <i className=" fa fa-trash-o"></i></button>
                                            <button onClick={()=> passartarefa("Análise")} className="btn btn-lg  btn-primary" style={{padding: 10 }}>Continuar <i className=" fa fa-chevron-right"></i></button>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    }

                    {results && (results.Type == "Análise" || displayanalise) &&
                    <div className="row wrapper-content animated fadeInRight " style={{marginTop: 20, paddingLeft: 30}} >
                        {BarraEtapas(results.Type)}
                        {results.Status == "em Andamento" &&
                        <div>
                            <div class="tabbable-panel white-bg" style={{marginTop: 20, padding: 20, marginBottom: 20}}>
                                
                                <form onSubmit={(e)=> doClassify(e)} role="form" >
                                <div style={{display: 'flex'}}>
                                    <div className="form-group" style={{marginRight:20,display: 'flex',  alignItems: "center"}}>
                                        <label style={{marginRight:20}}>Classificação: </label> 
                                        <select  onChange={(e)=> listartipos(e.target.value)} className="form-control">
                                            <option value="">Selecione...</option>
                                            {listaclassificacao &&  listaclassificacao.map((item, i) => {
                                                return(
                                                    <option key={i} value={item.id}>{item.value}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div className="form-group" style={{marginRight:20,display: 'flex',  alignItems: "center"}}>
                                        <label style={{marginRight:20}}>Tipo: </label> 
                                        <select  onChange={(e)=> setTipo(e.target.value)} className="form-control">
                                            {classificacao &&
                                                <option value="">Selecione...</option>
                                            }
                                            {listatipo &&  listatipo.map((item, i) => {
                                                return(
                                                    <option key={i} value={item.id}>{item.value}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div className="form-group" style={{ marginRight:20,display: 'flex',  alignItems: "center", }}>
                                        <button style={{marginTop:0}} className=" btn btn-sm btn-primary" type="submit"><strong>Classificar</strong></button>
                                    </div>
                                    
                                </div> 
                                    
                                
                                </form>
                            </div>
                            <div class="tabbable-panel white-bg" style={{marginTop: 20, padding: 20, marginBottom: 30}}>
                                <div class="tabbable-line">
                                    <ul class="nav nav-tabs ">
                                        <li class="active">
                                            <a href="#tab_default_1" data-toggle="tab">
                                            Formulário</a>
                                        </li>
                                        <li>
                                            <a href="#tab_default_2" data-toggle="tab">
                                            Comentários</a>
                                        </li>
                                        <li>
                                            <a href="#tab_default_3" data-toggle="tab">
                                            Anexos</a>
                                        </li>
                                    </ul>
                                    <div class="tab-content">
                                        <div class="tab-pane active" id="tab_default_1">

                                            <p style={{marginTop:40}}>
                                                Aqui vem o Formulário
                                            </p>
                                            
                                        </div>
                                        <div class="tab-pane" id="tab_default_2">
                                            <div className="row" style={{marginTop:40}}>
                                                <div className="col-xs-6 text-left">
                                                    <h3 >Comentários</h3>
                                                </div>
                                                <div className="col-xs-6 text-right">
                                                    <Link to="/novocomentarioanalise" state={{tarefa_id: myid, ideia_id: myprocessid, titulo: results.Process_Name}}><button className="btn btn-outline btn-sm btn-primary pull-right m-t-n-xs" type="submit"><strong>Novo Comentário</strong></button></Link>
                                                </div>
                                            </div>
                                            <div className="ibox-content ibox-heading  white-bg" style={{marginTop:10}}>
                                            
                                                                                    

                                                {messagescodes && messagescodes.map((subitem, i) => {
                                                    tipomessage = messages[subitem].Type
                                                    if (tipomessage == "Análise") {
                                                        return (
                                                            <div  key={i}>
                                                                <div className="row " style={{ marginBottom: 20}}>
                                                                    <div className="col-xs-1 center-block">
                                                                        <i className="fa fa-user-circle-o fa-3x center-block" style={{color:'#1ab394', margin: "auto"}}></i>
                                                                    </div>
                                                                    <div className="col-xs-11 text-left">
                                                                        <strong>{messages[subitem].User}</strong>
                                                                        <p>{messages[subitem].Description}</p>
                                                                        <small >{retornadata(messages[subitem].Creation_Date)}</small>
                                                                        
                                                                    </div>
                                                                </div>
                                                                <div className="row border-bottom" style={{ marginBottom: 20}}>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                })}

                                            </div>
                                        </div>
                                        <div class="tab-pane" id="tab_default_3">
                                            <p>
                                                Aqui vem os anexos
                                            </p>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {results.Type == "Análise" &&
                                <div style={{ display: 'flex',  alignItems: 'baseline', justifyContent:'end'}}>
                                    <button onClick={()=> passartarefa(results.Type)} className="btn btn-lg  btn-primary" style={{padding: 10 }}>Continuar <i className=" fa fa-chevron-right"></i></button>
                                </div>
                            }
                        </div>
                        }
                        {results.Status == "em Aberto" &&
                            <div>
                                <div class="tabbable-panel white-bg" style={{marginTop: 20, padding: 20, marginBottom: 30,height:100}}>
                                    <p>Clique no botão abaixo para começar a tarefa.</p>
                                </div>
                                <div style={{ display: 'flex',  alignItems: 'baseline', justifyContent:'end'}}>
                                
                                    <button onClick={() => iniciartarefa(results.Type)} className="btn btn-lg  btn-primary" style={{padding: 10 }}>Iniciar Tarefa </button>
                                </div>
                            </div>
                        }
                            
                        
                    </div>
                }


                {results && (results.Type == "Aprovação" && !displayanalise) &&
                        <div className="row wrapper-content animated fadeInRight " style={{marginTop: 20, paddingLeft: 30}} >
                            {BarraEtapas(results.Type)}

                            

                            {results.Status == "em Aberto" &&
                            
                                <div>
                                    <div class="tabbable-panel white-bg" style={{marginTop: 20, padding: 20, marginBottom: 30,height:100}}>
                                        <p>Clique no botão abaixo para começar a tarefa.</p>
                                    </div>
                                    <div style={{ display: 'flex',  alignItems: 'baseline', justifyContent:'end'}}>
                                        <button onClick={() => iniciartarefa(results.Type)} className="btn btn-xs  btn-primary" style={{padding: 10 }}>Iniciar Tarefa </button>
                                    </div>
                                </div>
                           
                            }
                            {(results.Status == "em Andamento" && displaydescarte == 'none') &&
                                <div>
                                    <div className="wrapper-content animated fadeInRight white-bg" style={{marginTop: 20, marginBottom: 30, paddingLeft: 20}} >
                                        <div >
                                            
                                                <h3 style={{marginBottom: 20}}>Razão para a aprovação/reprovação:</h3>
                                                <div className="form-group">
                                                    <textarea onChange={(e)=>setRazaoresultado(e.target.value)} rows="5"  cols="100" ></textarea>
                                                </div>
                                            
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex',  alignItems: 'baseline', justifyContent:'end'}}>
                                        
                                        <button onClick={()=> resultado(0)} className="btn btn-xs btn-outline btn-primary" style={{padding: 10, marginRight: 20 }}>Reprovar <i className=" fa fa-remove"></i></button>
                                        <button onClick={()=> resultado(1)} className="btn btn-xs  btn-primary" style={{padding: 10 }}>Aprovar <i className=" fa fa-check"></i></button>
                                    </div>
                                </div>
                            }
                        </div>
                    }

                    
                </div>

            </div>
        </div>
      );
    }
    
    export default TarefaUnica;
