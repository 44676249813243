
import { Outlet } from 'react-router-dom';

function App() {
  return (
    // antigo http://154.53.46.51:8090
    // o Outlet mostra a rota filha do componente
     <Outlet />
  );
}

export default App;
