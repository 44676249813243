import { Link } from "react-router-dom";


function Topbar() {

    

    return(

        <nav className="navbar navbar-static-top fundocinzamedio border-bottom" role="navigation" style={{marginBottom: 0, marginLeft: -20, marginRight: -20}}>
            <div className="navbar-header">
                    <a className="navbar-minimalize minimalize-styl-2 btn btn-primary " href=""><i className="fa fa-bars"></i> </a>
                    <form role="search" className="navbar-form-custom" action="search_results.html">
                        <div className="form-group">
                            <input type="text" placeholder="Procurar..." className="form-control" name="top-search" id="top-search" />
                        </div>
                    </form>
                    
            </div>
            <ul className="nav navbar-top-links navbar-right">
                        <li>
                            <span className="m-r-sm text-muted welcome-message">Olá, {sessionStorage.getItem("nomeusuario")}</span>
                        </li>
                        
                        <li>
                          <Link to="/logout"><i className="fa fa-sign-out"></i> Log out </Link>
                        </li>
            </ul>

        </nav>
    )
}

export default Topbar