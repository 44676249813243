import axios from "axios";

import Navbar from './Navbar'
import Topbar from './Topbar'
import Carregando from './Carregando'

import {useState, useEffect} from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';

function CampanhaUnica() {

    const locate = useLocation()

    const navigate = useNavigate()

    const [results, setResults] = useState()
    const [messages, setMessages] = useState()


    const myid = locate.state.campanha_id

    let tipomessage


    useEffect(() => {
        if (!sessionStorage.getItem("tokeninovacao")) {
            navigate('/login')
        }
        listresume( sessionStorage.getItem("tokeninovacao"))
    }, [])

    const listresume= async (mytoken) => {
        
        try {
            const res = await axios.post(`/Inovation/Campaign`, {TOKEN : mytoken, Id :myid});
            if (res.data) {
                setResults(res.data);
                setMessages(res.data.Messages.slice(0,-1))
                
            }
        } catch (err) {
            alert("Ocorreu um erro. Tente novamente.");
        }
    }  

  

    function retornadata(data) {
        let myday
        let mymonth
        let myyear
        if (data && isNaN(new Date(data).getDate())) {
            //não converteu direito
            myday = data.substring(0, 2)
            mymonth = data.substring(3, 5)
            myyear = data.substring(6,10)
        } else {
            myday = new Date(data).getDate()
            mymonth = new Date(data).getMonth()+1
            myyear = new Date(data).getFullYear()
        }
        const mydate = myday + '/' + mymonth + '/' + myyear
        return mydate
    }

    function verificadata(data) {
        return new Date(data).getFullYear()
            
    }

    return (

        <div id="wrapper" className="fundocinzaescuro">

           <Navbar />

            <div id="page-wrapper" className="gray-bg">

                <Topbar />
                    {(!results ) &&
                        <Carregando />
                    }
                    {results &&  [results].map((item, i) => {
                            return(
                                   
                                    <div key={item.ID} >
                                        <div className="row border-bottom white-bg" style={{minHeight: '100%', paddingLeft: 30}}>
                                        <div className="wrapper-content animated fadeInRight white-bg" >
                                            <div className="row">
                                                <div className="col-md-8">
                                                    <h5 style={{marginBottom:0}}>Número: {item.Number}</h5>
                                                    <h1 style={{marginTop:5, marginBottom: 10, fontWeight: "bold"}}>{item.Name}</h1>
                                                    <h5 style={{marginTop:0}}>Criado em: {retornadata(item.Creation_Date)} - Data Limite: {retornadata(item.Limit_Date)}</h5>
                                                    <p style={{marginTop:40}}>{item.Description}</p>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="widget style1 navy-bg text-center">
                                                        <h4>ABERTA ATÉ</h4>
                                                        <h2>{retornadata(item.Limit_Date)}</h2>
                                                    </div>
                                                    <Link to="/convidarcampanha" state={{campanha_id: myid, titulo: item.Name}}><button className="btn btn-outline btn-primary" style={{width:"100%"}} >Convidar para Campanha <i className=" fa fa-long-arrow-right"></i></button></Link>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        
                                    </div>
                                )
                            })
                    }
                    
                    {results && 
                        <div>
                            <div className="row" style={{marginTop:40}}>
                                    <div className="col-xs-6 text-left">
                                        <h3 >Comentários</h3>
                                    </div>
                                    <div className="col-xs-6 text-right">
                                    <Link to="/novocomentariocampanha" state={{campanha_id: myid, titulo: results.Name}}><button className="btn btn-outline btn-sm btn-primary pull-right m-t-n-xs" type="submit"><strong>Novo Comentário</strong></button></Link>
                                    </div>
                            </div>
                            <div className="ibox-content ibox-heading  white-bg" style={{marginTop:10}}>
                            {messages && messages.map((subitem, i) => {
                                   if (!subitem.Description.includes("processo como um participante")) {
                                        return (
                                        
                                            <div  key={i}>
                                                
                                                <div className="row " style={{ marginBottom: 20}}>
                                                    <div className="col-xs-1 center-block">
                                                        <i className="fa fa-user-circle-o fa-3x center-block" style={{color:'#1ab394', margin: "auto"}}></i>
                                                    </div>
                                                    <div className="col-xs-11 text-left">
                                                        <strong>{subitem.User}</strong>
                                                        <h4>{subitem.Description}</h4>
                                                        <small >{retornadata(subitem.Creation_Date)}</small>
                                                        
                                                    </div>
                                                </div>
                                                <div className="row border-bottom" style={{ marginBottom: 20}}>
                                                </div>
                                            </div>
                                        
                                        )
                                    }
                                   
                                    
                            })}
                            </div>
                        </div>
                       
                    }
                    

            </div>
        </div>
      );
    }
    
    export default CampanhaUnica;
