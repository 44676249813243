import axios from "axios";

import Navbar from './Navbar'
import Topbar from './Topbar'
import Carregando from './Carregando'

import {useState, useEffect} from 'react'
import { Link, useNavigate } from 'react-router-dom';

function Campanhas() {

    const [results, setResults] = useState()
    const [origem, setOrigem] = useState()
    const [status, setStatus] = useState()
    const [texto, setTexto] = useState()
    const [mydisplay, setMydisplay] = useState('none') 

    const navigate = useNavigate()

    useEffect(() => {
        if (!sessionStorage.getItem("tokeninovacao")) {
            navigate('/login')
        }
        listresume( sessionStorage.getItem("tokeninovacao"))
    }, [])

    const listresume= async (mytoken) => {
        
        try {
            const res = await axios.post(`/Inovation/Campaigns`, {TOKEN : mytoken});
            if (res.data) {
                setResults(res.data);
            }
        } catch (err) {
            alert("Ocorreu um erro. Tente novamente.");
        }
    }  

    const doFilter= async (e) => {
        e.preventDefault()
        
        try {
            
        } catch (err) {
            alert("Ocorreu um erro. Tente novamente.");
        }
    }  

    function mostrafiltro() {
        
    }

    function retornadata(data) {
        const myday = new Date(data).getDate()
        const mymonth = new Date(data).getMonth()+1
        const myyear = new Date(data).getFullYear()
        const mydate = myday + '/' + mymonth + '/' + myyear
        return mydate
    }

    return (

        <div id="wrapper" className="fundocinzaescuro">

           <Navbar />

            <div id="page-wrapper" className="gray-bg">

                <Topbar />
                {(!results) &&
                    <Carregando />
                }
                
                {results &&
                <div className="row border-bottom white-bg" style={{minHeight: '100%', paddingLeft: 30}}>
                    <div className="wrapper wrapper-content animated fadeInRight white-bg" >
                        <div className="row" style={{display:'flex', alignItems: 'center'}}>
                            <div className="col-xs-6 text-left" style={{display:'flex', alignItems: 'center'}}>
                                <h1>Campanhas</h1>
                                <button onClick={()=> mostrafiltro()} className="btn btn-primary " style={{marginTop: 10, marginLeft:10, alignItems: 'center', padding:5}}><i className="fa fa-filter fa-1x" ></i></button>
                                
                            </div>
                            
                            {sessionStorage.getItem("tipousuario") != 4 &&
                                <div className="col-xs-6 text-left" style={{display:'flex', alignItems: 'center', justifyContent: 'end'}}>
                                    <Link to="/novacampanha"><button className="btn btn-xs  btn-primary" style={{marginTop: 10, marginLeft:40, alignItems: 'center', padding:5}}><i className=" fa fa-plus-circle"></i> Nova Campanha </button></Link>
                                </div>
                            }
                        </div>

                        <div className="wrapper wrapper-content  white-bg" style={{marginTop:20,paddingLeft: 20, paddingBottom:40, display: mydisplay}}>
                                <h3 >Selecione o filtro</h3>
                                <form onSubmit={(e)=> doFilter(e)} role="form" >
                                <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                                    <div className="form-group" style={{marginRight:20}}><label>Origem: </label> 
                                        <select  onChange={(e)=> setOrigem(e.target.value)} className="form-control">
                                            <option value=""></option>
                                            <option value="minhas">Minhas Tarefas</option>
                                            <option value="todas">Todas as Tarefas</option>
                                        </select>
                                    </div>
                                    <div className="form-group" style={{marginRight:20}}><label>Status: </label> 
                                        <select  onChange={(e)=> setStatus(e.target.value)} className="form-control">
                                            <option value=""></option>
                                            <option value="em Aberto">em Aberto</option>
                                            <option value="em Andamento">em Andamento</option>
                                            <option value="Cancelado">Cancelado</option>
                                            <option value="Aprovado">Aprovado</option>
                                            <option value="Reprovado">Reprovado</option>
                                        </select>
                                    </div>
                                    <div className="form-group" style={{marginRight:20}}><label>Texto: </label> 
                                        <input type="text" className="form-control" required="" onChange={(e)=> setTexto(e.target.value)} />
                
                                    </div>
                                </div> 
                                    
                                <button className="form-group btn btn-sm btn-primary pull-left m-t-n-xs" type="submit"><strong>Filtrar</strong></button>
                                </form>

                        </div>
                        {results &&  results.map((item, i) => {
                                return(
                                    <div key={item.ID} >
                                        <div className="ibox-content ibox-heading" style={{marginTop:20}}>
                                                <h5>Número: {item.NUMERO} - ID: {item.ID}</h5>
                                                <h3>{item.TITULO}</h3>
                                   
                                                <h5>Criado em: {retornadata(item.DATA_ABERTURA)} - Data Limite: {retornadata(item.DATA_LIMITE)}</h5>

                                                <Link to="/campanhaunica" state={{campanha_id: item.ID}}><button className="btn btn-xs btn-outline btn-primary" >Info <i className=" fa fa-long-arrow-right"></i></button></Link>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                }
            </div>
        </div>
      );
    }
    
    export default Campanhas;
