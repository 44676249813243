import axios from "axios";

import Navbar from './Navbar'
import Topbar from './Topbar'

import {useState, useEffect} from 'react'
import { Link, useNavigate } from 'react-router-dom';

function Tarefas() {

    const [results, setResults] = useState([])

    const [origem, setOrigem] = useState()
    const [status, setStatus] = useState()
    const [numero, setNumero] = useState()
    const [tipo, setTipo] = useState()

    const [mydisplay, setMydisplay] = useState('none') 

    const navigate= useNavigate()

    useEffect(() => {
        if (!sessionStorage.getItem("tokeninovacao")) {
            navigate('/login')
        }
        listresume( sessionStorage.getItem("tokeninovacao"))
    }, [])

    const listresume= async (mytoken) => {
        
        try {
            const res = await axios.post(`/User/Tasks`, {TOKEN :sessionStorage.getItem("tokeninovacao")});
            if (res.data) {
                setResults(res.data);
            }
        } catch (err) {
            alert("Ocorreu um erro. Tente novamente.");
        }
    }  



    const doFilter= async (e) => {
        e.preventDefault()
        
        try {
            const res = await axios.post(`/User/Tasks`, {TOKEN : sessionStorage.getItem("tokeninovacao"), Status: status, Number: numero, Type: tipo});
            if (res.data) {
                setResults(res.data);
                setMydisplay('none')
            } else {
                alert("nada encocntrado")
            }
        } catch (err) {
            alert("Ocorreu um erro. Tente novamente.");
        }
    }  

    function mostrafiltro() {
        if (mydisplay == 'block') {
            setMydisplay('none')
        }else {
            setMydisplay('block')
        }
    }

    function retornadata(data) {

        //alert(data)
        let myday
        let mymonth
        let myyear
        if (isNaN(new Date(data).getDate())) {
            //não converteu direito
            myday = data.substring(0, 2)
            mymonth = data.substring(3, 5)
            myyear = data.substring(6,10)
        } else {
            myday = new Date(data).getDate()
            mymonth = new Date(data).getMonth()+1
            myyear = new Date(data).getFullYear()
        }
        const mydate = myday + '/' + mymonth + '/' + myyear
        return mydate
    }

    return (

        <div id="wrapper" className="fundocinzaescuro">

           <Navbar />

            <div id="page-wrapper" className="gray-bg">

                <Topbar />

                
                <div className="row border-bottom white-bg" style={{paddingLeft: 30}}>
                    <div className="wrapper wrapper-content animated fadeInRight " >
                        <div className="row">
                            <div className="col-xs-6 text-left" style={{display:'flex', alignItems: 'center'}}>
                                <h1>Tarefas</h1>
                                <button onClick={()=> mostrafiltro()} className="btn btn-primary " style={{marginTop: 10, marginLeft:10, alignItems: 'center', padding:5}}><i className="fa fa-filter fa-1x" ></i></button>
                                
                            </div>
                           
                        </div>
                    </div>
                </div>

                <div className="wrapper wrapper-content  white-bg" style={{marginTop:20,paddingLeft: 20, paddingBottom:40, display: mydisplay}}>


                                <h3 >Selecione o filtro</h3>
                                
                                <form onSubmit={(e)=> doFilter(e)} role="form" >
                                <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                                    <div className="form-group" style={{marginRight:20}}><label>Origem: </label> 
                                        <select  onChange={(e)=> setOrigem(e.target.value)} className="form-control">
                                            <option value=""></option>
                                            <option value="minhas">Minhas Tarefas</option>
                                            <option value="todas">Todas as Tarefas</option>
                                        </select>
                                    </div>
                                    <div className="form-group" style={{marginRight:20}}><label>Status: </label> 
                                        <select  onChange={(e)=> setStatus(e.target.value)} className="form-control">
                                            <option value=""></option>
                                            <option value="em Aberto">em Aberto</option>
                                            <option value="em Andamento">em Andamento</option>
                                            <option value="Cancelado">Cancelado</option>
                                            <option value="Terminado">Terminado</option>
                                            <option value="Pausado">Pausado</option>
                                            <option value="Planejado">Planejado</option>
                                        </select>
                                    </div>
                                    <div className="form-group" style={{marginRight:20}}><label>Tipo: </label> 
                                        <select  onChange={(e)=> setTipo(e.target.value)} className="form-control">
                                            <option value=""></option>
                                            <option value="Validação">Validação</option>
                                            <option value="Análise">Análise</option>
                                            <option value="Aprovação">Aprovação</option>
                                            <option value="Planejamento">Planejamento</option>

                                        </select>
                                    </div>
                                    <div className="form-group" style={{marginRight:20}}><label>Número: </label> 
                                        <input type="text" className="form-control" required="" onChange={(e)=> setNumero(e.target.value)} />
                
                                    </div>
                                </div> 
                                    
                                <button className="form-group btn btn-sm btn-primary pull-left m-t-n-xs" type="submit"><strong>Filtrar</strong></button>
                                        
                                    
                                     
                                    
                                </form>
                                

                            
                        
                    </div>
                   
                            
                    <div className="wrapper wrapper-content  white-bg" style={{marginTop:20,paddingLeft: 20, paddingBottom:40}}>
                    <div className="row ibox float-e-margins " >
                        <div className="col-lg-12">
                            <div className="white-bg" style={{marginTop:20}}>
                                <table class="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Ideia</th>
                                        <th>Tipo</th>
                                        <th>Criada em</th>
                                        <th>Prazo</th>
                                        <th>Status</th>
                                        
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                        
                                    {results &&  results.map((item, i) => {
                                        return(

                                                <tr key={item.ID}>
                                                    <td>{item.NUMERO} - {item.ID} - {item.PROCESSO_SOLICITANTE} - {item.CLIENTE}</td>
                                                    <td>{item.PROCESSO_NOME}</td>
                                                    <td>{item.TIPO}</td>
                                                    <td>{retornadata(item.DATA_ABERTURA)}</td>
                                                    <td>{retornadata(item.DATA_LIMITE)}</td>
                                                    <td>{item.STATUS}</td>
                                                    
                                                    <td><Link to="/tarefaunica" state={{processo_id: item.PROCESSO_ID, processo_numero: item.NUMERO}}><button className="btn btn-xs btn-outline btn-primary" >Info <i className=" fa fa-long-arrow-right"></i></button></Link></td>
                                                </tr>
                                       
                                        )
                                    })}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    </div>

                </div>

            </div>

        
      );
    }
    
    export default Tarefas;
