import axios from "axios";

import Navbar from './Navbar'
import Topbar from './Topbar'


import {useState, useEffect} from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';

function TarefaUnicaAnalise() {

    const locate = useLocation()

    const navigate = useNavigate()

    const [results1, setResults1] = useState([])
    const [messages, setMessages] = useState([])

    const [displaydescarte, setDisplaydescarte] = useState('none')
    const [razao, setRazao] = useState('')
    const [tarefa, setTarefa] = useState('') 
    const [displayanalise, setDisplayanalise] = useState(false)
    const [razaoresultado, setRazaoresultado] = useState('')


    const mytoken = sessionStorage.getItem("tokeninovacao")
    const myid = locate.state.tarefa_id
    

    useEffect(() => {
        if (!sessionStorage.getItem("tokeninovacao")) {
            navigate('/login')
        }
        listresume()
    }, [tarefa])

    const listresume= async () => {
        try {
            const res = await axios.post(`/Process/IdentifyTask`, {TOKEN : mytoken, Id: myid});
            if (res.data.Type) {
                setResults1(res.data);
                alert(res.data.Id)
            }
        } catch (err) {
            alert("Ocorreu um erro. Tente novamente.");
        }
    }  


    const iniciartarefa= async (tipotarefa) => {
        
        try {
            
            const res = await axios.post(`/Process/StartTask`, {TOKEN : mytoken, Id_Task: myid});
            if (res.data.Return) {
                //por enquanto só uso o state abaixo para a pagina ser relida
                setTarefa(tipotarefa)
                //navigate('/tarefaunica', {state: {tarefa_id: myid}})
            } else {
                alert('Erro na abertura da tarefa!')
            }
        } catch (err) {
            alert("Ocorreu um erro na chamada.");
        }
    } 
    
    
    const passartarefa = async (tipotarefa) => {
        
        try {
            //vai fechar essa tarefa de Validação, com status Terminado. Vai criar uma tarefa de Análise, com status em Aberto
            //na ideia não vai mudar nada
            const res = await axios.post(`/Process/FinishTask`, {TOKEN : mytoken, Id_Task: myid, AnwserFollowingQuestion:true, AnwserValue:true});
            if (res.data.Return) {
                //setTarefa(tipotarefa)
                //navigate('/tarefaunica', {state: {tarefa_id: myid}})
                listresume()
            } else {
                alert('Erro na passagem para análise!')
            }
        } catch (err) {
            alert("Ocorreu um erro na chamada.");
        }
    } 

    

    function retornadata(data) {
        const myday = new Date(data).getDate()
        const mymonth = new Date(data).getMonth()+1
        const myyear = new Date(data).getFullYear()
        const mydate = myday + '/' + mymonth + '/' + myyear
        return mydate
    }

    function BarraEtapas() {


        return(
    
            <div style={{ display: 'flex',  alignItems: 'baseline', justifyContent:'start'}}>

                <h3 className="widget   " style={{ marginRight:20, borderColor: 'grey', borderWidth: 1, borderStyle: 'solid', hright: 50}}>Dados da Ideia </h3>
               
               <i style={{ marginRight:20}}  className=" fa fa-chevron-right"></i>


                <h3 className="widget   " style={{ marginRight:20, borderColor: 'grey', borderWidth: 1, borderStyle: 'solid', hright: 50}}>Validação </h3>
               
                <i style={{ marginRight:20}}  className=" fa fa-chevron-right"></i>
 
                <h3  className="widget btn-primary " style={{ marginRight:20, borderColor: 'grey', borderWidth: 1, borderStyle: 'solid', hright: 50}}>Análise </h3>

                <i style={{ marginRight:20}}  className=" fa fa-chevron-right"></i>

                <h3  className="widget  " style={{ marginRight:20, borderColor: 'grey', borderWidth: 1, borderStyle: 'solid', hright: 50}}>Aprovação</h3>
                
                <i style={{ marginRight:20}}  className=" fa fa-chevron-right"></i>

                 <h3  className="widget  " style={{ marginRight:20, borderColor: 'grey', borderWidth: 1, borderStyle: 'solid', hright: 50}}>Planejamento</h3>
               
            </div>
        )
    
    }

    return (

        <div id="wrapper" className="fundocinzaescuro">

           <Navbar />

            <div id="page-wrapper" className="gray-bg">

                <Topbar />

                    {results1 &&  [results1].map((item, i) => {
                            return(
                                    
                                    <div key={item.ID} >
                                        <div className="row border-bottom white-bg" style={{minHeight: '100%', paddingLeft: 30}}>
                                        <div className="wrapper-content animated fadeInRight white-bg" >
                                            <div className="row" style={{ display: 'flex',  alignItems: 'baseline'}}>
                                                <h1 >{item.Process_Name}</h1>
                                                <Link to="/ideiaunica" state={{ideia_id: item.Process_Id}}><button className="btn btn-xs btn-outline btn-primary" style={{marginLeft:30, marginBottom: 10 }}>Ver Ideia <i className=" fa fa-long-arrow-right"></i></button></Link>
                                            </div>
                                        </div>
                                        </div>
                                        
                                    </div>
                                )
                            })
                    }
                    
   

                    {results1  &&
                    <div className="row wrapper-content animated fadeInRight " style={{marginTop: 20, paddingLeft: 30}} >
                        {BarraEtapas()}
                        {results1.Status == "em Andamento" &&
                        <div>
                            <div class="tabbable-panel white-bg" style={{marginTop: 20, padding: 20, marginBottom: 30}}>
                                <div class="tabbable-line">
                                    <ul class="nav nav-tabs ">
                                        <li class="active">
                                            <a href="#tab_default_1" data-toggle="tab">
                                            Formulário</a>
                                        </li>
                                        <li>
                                            <a href="#tab_default_2" data-toggle="tab">
                                            Comentários</a>
                                        </li>
                                        <li>
                                            <a href="#tab_default_3" data-toggle="tab">
                                            Anexos</a>
                                        </li>
                                    </ul>
                                    <div class="tab-content">
                                        <div class="tab-pane active" id="tab_default_1">

                                            <p style={{marginTop:40}}>
                                                Aqui vem o Formulário
                                            </p>
                                            
                                        </div>
                                        <div class="tab-pane" id="tab_default_2">
                                            <div className="row" style={{marginTop:40}}>
                                                <div className="col-xs-6 text-left">
                                                    <h3 >Comentários</h3>
                                                </div>
                                                <div className="col-xs-6 text-right">
                                                    <Link to="/novocomentarioideia" state={{ideia_id: myid, titulo: results1.Name}}><button className="btn btn-outline btn-sm btn-primary pull-right m-t-n-xs" type="submit"><strong>Novo Comentário</strong></button></Link>
                                                </div>
                                            </div>
                                            <div className="ibox-content ibox-heading  white-bg" style={{marginTop:10}}>
                                            {messages && messages.map((subitem, i) => {
                                            return (
                                                <div  key={i}>
                                                    
                                                    <div className="row " style={{ marginBottom: 20}}>
                                                        <div className="col-xs-1 center-block">
                                                            <i className="fa fa-user-circle-o fa-3x center-block" style={{color:'#1ab394', margin: "auto"}}></i>
                                                        </div>
                                                        <div className="col-xs-11 text-left">
                                                            <strong>{subitem.User}</strong>
                                                            <p>{subitem.Description}</p>
                                                            <small >{retornadata(subitem.Creation_Date)}</small>
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="row border-bottom" style={{ marginBottom: 20}}>
                                                    </div>
                                                </div>
                                                
                                            )
                                            })}
                                            </div>
                                        </div>
                                        <div class="tab-pane" id="tab_default_3">
                                            <p>
                                                Aqui vem os anexos
                                            </p>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {results1.Type == "Análise" &&
                                <div style={{ display: 'flex',  alignItems: 'baseline', justifyContent:'end'}}>
                                    <button onClick={()=> passartarefa(results1.Type)} className="btn btn-xs  btn-primary" style={{padding: 10 }}>Continuar <i className=" fa fa-chevron-right"></i></button>
                                </div>
                            }
                        </div>
                        }
                        {results1.Status == "em Aberto" &&
                            <div>
                                <div class="tabbable-panel white-bg" style={{marginTop: 20, padding: 20, marginBottom: 30,height:100}}>
                                    <p>Clique no botão abaixo para começar a tarefa.</p>
                                </div>
                                <div style={{ display: 'flex',  alignItems: 'baseline', justifyContent:'end'}}>
                                
                                    <button onClick={() => iniciartarefa(results1.Type)} className="btn btn-xs  btn-primary" style={{padding: 10 }}>Iniciar Tarefa </button>
                                </div>
                            </div>
                        }
                            
                        
                    </div>
                }

            </div>
        </div>
      );
    }
    
    export default TarefaUnicaAnalise;
