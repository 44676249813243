import logo from './logo-inovacao.png'
import axios from "axios";

import {useState} from 'react'
import { Link, useNavigate } from 'react-router-dom';

function Register() {

    const navigate = useNavigate()

    const [nome, setNome] = useState()
    const [empresa, setEmpresa] = useState()
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()

    const  doRegister = async (e) => {
        e.preventDefault()
        if (!nome || !empresa || !email || !password) {
            alert("Faltam dados para o registro!")
            return
        }
        try {
            const res = await axios.post(`Account/InsertCompany`, {TOKEN: "@@@@@", Company: empresa, Name: nome, eMail: email, Password: password})
            if (res.data.Return == false) {
                if (res.data.Code == 100) {
                    alert("E-mail já cadastrado!")
                    return
                    //code 103 empresa já existe
                } else {
                    alert("erro na inclusao da empresa")
                }
            } else {
                if (res.data.Code) {
                    alert(res.data.Code)
                    navigate('/login')
                } 
            }
        }
        catch(err){
            alert(err)
        }

    }


  return (
    <div className="middle-box text-center loginscreen animated fadeInDown">
        <div>
            <img src={logo} alt="logo" style={{height:120, width:120,}} />

            <h2>Registre-se no SUITI</h2>

            <form onSubmit={(e)=> doRegister(e)} class="m-t" role="form" >
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Empresa" required=""  onChange={(e)=> setEmpresa(e.target.value)}/>
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Nome" required=""  onChange={(e)=> setNome(e.target.value)}/>
                </div>
                <div class="form-group">
                    <input type="email" class="form-control" placeholder="Email" required=""  onChange={(e)=> setEmail(e.target.value)}/>
                </div>
                <div class="form-group">
                    <input type="password" class="form-control" placeholder="Senha" required=""  onChange={(e)=> setPassword(e.target.value)}/>
                </div>
                <div class="form-group">
                        <div class="checkbox i-checks"><label> <input type="checkbox" /><i></i> Concordo com os termos e políticas </label></div>
                </div>
                <button type="submit" class="btn btn-primary block full-width m-b">Registrar</button>

                <p class="text-muted text-center"><small>Já tem uma conta?</small></p>
                <Link to="/login"><a class="btn btn-sm btn-white btn-block">Login</a></Link>
            </form>
            
        </div>
    </div>
  );
}

export default Register;