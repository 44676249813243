import logo from './logo-inovacao.png'
import axios from "axios";

import {useState} from 'react'
import { Link, useNavigate } from 'react-router-dom';

function Login() {

    const navigate = useNavigate()

    const [email, setEmail] = useState()
    const [password, setPassword] = useState()

    const  doLogin = async (e) => {
        e.preventDefault()
        try {
            axios.defaults.baseURL = 'https://it2mweb.com.br:8093/';

            const res = await axios.post(`Account/Login`, {Login: email, Password: password})
            if (res.data.Token) {
                sessionStorage.setItem("tokeninovacao", res.data.Token);
                sessionStorage.setItem("nomeusuario", res.data.Name);
                sessionStorage.setItem("tipousuario", res.data.Type);
                sessionStorage.setItem("codigoempresa", res.data.Id_Company);
                sessionStorage.setItem("codigousuario", res.data.Id);
                navigate('/home')
            } else {
                alert(res.data.Description)
            }
        }
        catch(err){
            alert("Erro" + err)
        }

    }


  return (
    <div className="middle-box text-center loginscreen animated fadeInDown">
        <div>
            <img src={logo} alt="logo" style={{height:120, width:120,}} />

            <h2>Bem-vindo ao SUITI</h2>
            
            <p>Faça login abaixo:</p>
            <form onSubmit={(e)=> doLogin(e)} className="m-t" >
                <div className="form-group">
                    <input type="text" className="form-control" placeholder="Email" required="" onChange={(e)=> setEmail(e.target.value)}/>
                </div>
                <div className="form-group">
                    <input type="password" className="form-control" placeholder="Senha" required="" onChange={(e)=> setPassword(e.target.value)} />
                </div>
                <button type="submit" className="btn btn-primary block full-width m-b">Login</button>

                <p>Esqueceu a senha?</p>
                <p className="text-muted text-center"><small>Não tem uma conta?</small></p>
                <Link to="/register"><button className="btn btn-sm btn-white btn-block" >Cria uma conta</button></Link>
            </form>
            
        </div>
    </div>
  );
}

export default Login;