import axios from "axios";

import Navbar from './Navbar'
import Topbar from './Topbar'

import {useState, useEffect} from 'react'
import { Link, useNavigate } from 'react-router-dom';

function Usuarios() {

    const [results, setResults] = useState([])
    const [origem, setOrigem] = useState()
    const [status, setStatus] = useState()
    const [texto, setTexto] = useState()
    const [mydisplay, setMydisplay] = useState('none') 

    const navigate = useNavigate()

    useEffect(() => {
        if (!sessionStorage.getItem("tokeninovacao")) {
            navigate('/login')
        }
        listresume( sessionStorage.getItem("tokeninovacao"))
    }, [])

    const listresume= async (mytoken) => {
        
        try {
            const res = await axios.post(`/Account/ListUsers`, {TOKEN : mytoken});
            if (res.data) {
                setResults(res.data);
            }
        } catch (err) {
            alert("Ocorreu um erro. Tente novamente.");
        }
    }  

    

    return (

        <div id="wrapper" className="fundocinzaescuro">

           <Navbar />

            <div id="page-wrapper" className="gray-bg">

                <Topbar />

                
                <div className="row border-bottom white-bg" style={{minHeight: '100%', paddingLeft: 30}}>
                    <div className="wrapper wrapper-content animated fadeInRight white-bg" >
                        <div className="row" style={{display:'flex', alignItems: 'center'}}>
                            <div className="col-xs-6 text-left" style={{display:'flex', alignItems: 'center'}}>
                                <h1>Usuários</h1>
                                
                                
                            </div>
                            <div className="col-xs-6 text-left" style={{display:'flex', alignItems: 'center', justifyContent: 'end'}}>
                                <Link to="/cadastrousuario"><button className="btn btn-xs  btn-primary" style={{marginTop: 10, marginLeft:40, alignItems: 'center', padding:5}}><i className=" fa fa-plus-circle"></i> Novo Usuário </button></Link>
                            </div>
                        </div>


                        <div className="wrapper wrapper-content  white-bg" style={{marginTop:20,paddingLeft: 20, paddingBottom:40}}>
                            <div className="row ibox float-e-margins " >
                                <div className="col-lg-12">
                                    <div className="white-bg" style={{marginTop:20}}>
                                        <table class="table table-bordered">
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Nome</th>
                                                <th>Email</th>
                                                <th>Tipo</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                
                                            {results &&  results.map((item, i) => {
                                                return(

                                                        <tr key={item.ID}>
                                                            <td>{item.ID}</td>
                                                            <td>{item.NOME}</td>
                                                            <td>{item.EMAIL}</td>
                                                            {item.TIPO == "SOLICITANTE" ?
                                                                <td>USUÁRIO</td>
                                                            :
                                                                <td>{item.TIPO}</td>
                                                            }
                                                            <td>{item.DEPARTAMENTO}</td>
                                                        </tr>
                                            
                                                )
                                            })}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                   


                </div>
                
            </div>


            
        
            

        </div>
      );
    }
    
    export default Usuarios;
