import React from 'react';
import ReactDOM from 'react-dom/client';

import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider, Route, Navigate } from 'react-router-dom';

import App from './App';
import Home from './Home'
import Login from './Login'
import Register from './Register'
import Ideias from './Ideias'
import NovaIdeia from './NovaIdeia'
import IdeiaUnica from './IdeiaUnica'
import NovoComentarioIdeia from './NovoComentarioIdeia'
import Tarefas from './Tarefas'
import TarefaUnica from './TarefaUnica'
import TarefaUnicaAnalise from './TarefaUnicaAnalise'
import Logout from './Logout'
import CadastroUsuario from './CadastroUsuario'
import Usuarios from './Usuarios'
import NovoComentarioAnalise from './NovoComentarioAnalise'
import Campanhas from './Campanhas'
import CampanhaUnica from './CampanhaUnica'
import NovaCampanha from './NovaCampanha'
import NovoComentarioCampanha from './NovoComentarioCampanha'
import ConvidarCampanha from './ConvidarCampanha'



const myrouter = createBrowserRouter(
  [
    {
      element: <App />,
      children: [
        {
          path: "/",
          element: <Login />
        },
        {
          path: "/login",
          element: <Login />
        },
        {
          path: "/register",
          element: <Register />
        },
        {
          path: "/home",
          element: <Home />,
        },
        {
          path: "/ideias",
          element: <Ideias />
        },
        {
          path: "/novaideia",
          element: <NovaIdeia />
        },
        {
          path: "/ideiaunica",
          element: <IdeiaUnica />
        },
        {
          path: "/novocomentarioideia",
          element: <NovoComentarioIdeia />
        },
        {
          path: "/tarefas",
          element: <Tarefas />
        },
        {
          path: "/tarefaunica",
          element: <TarefaUnica />
        },
        {
          path: "/tarefaunicaanalise",
          element: <TarefaUnicaAnalise />
        },
        {
          path: "/logout",
          element: <Logout />
        },
        {
          path: "/cadastrousuario",
          element: <CadastroUsuario />
        },
        {
          path: "/usuarios",
          element: <Usuarios />
        },
        {
          path: "/novocomentarioanalise",
          element: <NovoComentarioAnalise />
        },
        {
          path: "/campanhas",
          element: <Campanhas />
        },
        {
          path: "/campanhaunica",
          element: <CampanhaUnica />
        },  
        {
          path: "/novacampanha",
          element: <NovaCampanha />
        },
        {
          path: "/novocomentariocampanha",
          element: <NovoComentarioCampanha />
        },
        {
          path: "/convidarcampanha",
          element: <ConvidarCampanha />
        }
      ]
    }
  ]
)



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={myrouter} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
